var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-col-100"},[_c('el-form',{ref:"dataForm",staticStyle:{"height":"90%","overflow":"auto"},attrs:{"model":_vm.dataForm,"rules":_vm.rules}},[_c('AssociationScroll',[_c('AssociationScrollView',{attrs:{"title":"保险公司","name":"0"}},[_c('TextInputContainer',[_c('el-form-item',{attrs:{"prop":"companyKey","rules":{
              required: true,
              message: '请选择保险公司',
              trigger: 'change',
            }}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"optionConfig":{
                value: 'dictKey',
                label: 'dictValue',
              },"config":{
                type: 'select',
                option: _vm.dictList.insuranceCompany,
                modelTextKey: 'companyName',
                label: '保险公司：',
                required: true,
              }},on:{"returnVal":_vm.getKeyToValue},model:{value:(_vm.dataForm.companyKey),callback:function ($$v) {_vm.$set(_vm.dataForm, "companyKey", $$v)},expression:"dataForm.companyKey"}})],1),_c('el-form-item',{attrs:{"prop":"branch","rules":{
              required: true,
              message: '请选择分支机构',
              trigger: 'change',
            }}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"optionConfig":{
                value: 'dictKey',
                label: 'dictValue',
              },"config":{
                type: 'select',
                option: _vm.dictList.branchList,
                modelTextKey: 'branch',
                label: '分支机构：',
                required: true,
              }},on:{"returnVal":_vm.getKeyToValue},model:{value:(_vm.dataForm.branch),callback:function ($$v) {_vm.$set(_vm.dataForm, "branch", $$v)},expression:"dataForm.branch"}})],1)],1)],1),_c('AssociationScrollView',{attrs:{"title":"保障内容","name":"1"}},[_c('div',{staticStyle:{"margin-top":"24px"},attrs:{"flex":""}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.dataForm.coverageList,"header-cell-style":{ background: '#F5F7FA', color: '#606266' }}},[_c('el-table-column',{attrs:{"type":"index","label":"ID","width":"55"}}),_c('el-table-column',{attrs:{"prop":"name","label":"险别名称"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('TextInput',{attrs:{"isText":"","config":{
                    type: 'input',
                    width: '100%',
                  }},model:{value:(row.insCoverage.insuranceName),callback:function ($$v) {_vm.$set(row.insCoverage, "insuranceName", $$v)},expression:"row.insCoverage.insuranceName"}})]}}])}),_c('el-table-column',{attrs:{"prop":"date","label":"限额类型"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('TextInput',{attrs:{"isText":"","config":{
                    type: 'input',
                    width: '100%',
                  }},model:{value:(row.limitTypeStrings),callback:function ($$v) {_vm.$set(row, "limitTypeStrings", $$v)},expression:"row.limitTypeStrings"}})]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"限额值"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('TextInput',{attrs:{"isText":"","config":{
                    type: 'input',
                    width: '100%',
                  }},model:{value:(row.limitValueStrings),callback:function ($$v) {_vm.$set(row, "limitValueStrings", $$v)},expression:"row.limitValueStrings"}})]}}])}),_c('el-table-column',{attrs:{"prop":"date","label":"操作","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function({ $index, row }){return [(!_vm.isEdit)?_c('el-button',{staticStyle:{"color":"#0080ff"},attrs:{"type":"text"},on:{"click":function($event){return _vm.editRiskRow(row, $index)}}},[_vm._v("查看详情")]):_vm._e(),(_vm.isEdit)?_c('el-button',{staticStyle:{"color":"#0080ff"},attrs:{"type":"text"},on:{"click":function($event){return _vm.editRiskRow(row, $index)}}},[_vm._v("编辑")]):_vm._e(),(_vm.isEdit)?_c('el-button',{staticStyle:{"color":"#f64a2d"},attrs:{"type":"text"},on:{"click":function($event){return _vm.deleteRiskRow($index, row, 'coverageIds')}}},[_vm._v("删除")]):_vm._e()]}}])})],1),(_vm.isEdit)?_c('div',{staticClass:"table-add-btn",on:{"click":_vm.addRisk}},[_vm._v(" 添加险别 ")]):_vm._e()],1)]),_c('AssociationScrollView',{attrs:{"title":"服务方案","name":"2"}},[_c('div',{staticStyle:{"margin-top":"24px"},attrs:{"flex":""}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.dataForm.servicePlanList,"header-cell-style":{ background: '#F5F7FA', color: '#606266' }}},[_c('el-table-column',{attrs:{"type":"index","label":"ID","width":"55"},scopedSlots:_vm._u([{key:"default",fn:function({ row, $index }){return [_c('el-form-item',[_vm._v(" "+_vm._s($index + 1)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"serviceName","label":"服务项名称"},scopedSlots:_vm._u([{key:"default",fn:function({ row, $index }){return [_c('el-form-item',{attrs:{"prop":'servicePlanList.' + $index + '.serviceName',"rules":{
                    required: true,
                    message: '请输入服务项名称',
                    trigger: ['change', 'blur'],
                  }}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                      type: 'select',
                      width: '100%',
                      modelTextKey: 'serviceName',
                      option: _vm.dictList.ServiceProjectOptions,
                    },"optionConfig":{
                      value: 'id',
                      label: 'serviceName',
                    }},on:{"returnVal":function($event){return _vm.getKeyToValue($event, row)}},model:{value:(row.serviceDictId),callback:function ($$v) {_vm.$set(row, "serviceDictId", $$v)},expression:"row.serviceDictId"}})],1)]}}])}),_c('el-table-column',{attrs:{"prop":"date","label":"服务项目标准"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                      type: 'input',
                      width: '100%',
                    }},model:{value:(row.serviceStandard),callback:function ($$v) {_vm.$set(row, "serviceStandard", $$v)},expression:"row.serviceStandard"}})],1)]}}])}),_c('el-table-column',{attrs:{"prop":"date","label":"服务项目金额"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                      type: 'input',
                      width: '100%',
                      isNum: true,
                    }},model:{value:(row.serviceAmount),callback:function ($$v) {_vm.$set(row, "serviceAmount", $$v)},expression:"row.serviceAmount"}})],1)]}}])}),(_vm.isEdit)?_c('el-table-column',{attrs:{"prop":"date","label":"操作","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function({ $index, row }){return [_c('el-form-item',[_c('el-button',{staticStyle:{"color":"#f64a2d"},attrs:{"type":"text"},on:{"click":function($event){return _vm.deleteSerRow($index, row, 'servicePlanIds')}}},[_vm._v("删除")])],1)]}}],null,false,1909286516)}):_vm._e()],1),(_vm.isEdit)?_c('div',{staticClass:"table-add-btn",on:{"click":_vm.addSerRow}},[_vm._v(" 添加服务 ")]):_vm._e()],1)])],1)],1),_c('ComSafeguardSchemeAdd',{attrs:{"show":_vm.showAdd,"isEdit":_vm.isEdit,"detailData":_vm.currentRisk},on:{"update:show":function($event){_vm.showAdd=$event},"submit":_vm.submitAdd}}),_c('div',{staticClass:"sd-bottom-btn"},[_c('el-button',{on:{"click":_vm.back}},[_vm._v("返回")]),(_vm.isEdit)?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submitForm}},[_vm._v("保存")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }