<template>
  <div class="flex-col-100">
    <el-form
      style="height: 90%; overflow: auto"
      :model="dataForm"
      ref="dataForm"
      :rules="rules"
    >
      <AssociationScroll>
        <AssociationScrollView title="保险公司" name="0">
          <TextInputContainer>
            <el-form-item
              prop="companyKey"
              :rules="{
                required: true,
                message: '请选择保险公司',
                trigger: 'change',
              }"
            >
              <TextInput
                :isText="!isEdit"
                @returnVal="getKeyToValue"
                v-model="dataForm.companyKey"
                :optionConfig="{
                  value: 'dictKey',
                  label: 'dictValue',
                }"
                :config="{
                  type: 'select',
                  option: dictList.insuranceCompany,
                  modelTextKey: 'companyName',
                  label: '保险公司：',
                  required: true,
                }"
              >
              </TextInput>
            </el-form-item>
            <el-form-item
              prop="branch"
              :rules="{
                required: true,
                message: '请选择分支机构',
                trigger: 'change',
              }"
            >
              <TextInput
                :isText="!isEdit"
                @returnVal="getKeyToValue"
                v-model="dataForm.branch"
                :optionConfig="{
                  value: 'dictKey',
                  label: 'dictValue',
                }"
                :config="{
                  type: 'select',
                  option: dictList.branchList,
                  modelTextKey: 'branch',
                  label: '分支机构：',
                  required: true,
                }"
              >
              </TextInput>
            </el-form-item>
          </TextInputContainer>
        </AssociationScrollView>
        <AssociationScrollView title="保障内容" name="1">
          <div style="margin-top: 24px" flex>
            <el-table
              :data="dataForm.coverageList"
              style="width: 100%"
              :header-cell-style="{ background: '#F5F7FA', color: '#606266' }"
            >
              <el-table-column type="index" label="ID" width="55">
              </el-table-column>
              <el-table-column prop="name" label="险别名称">
                <template slot-scope="{ row }">
                  <TextInput
                    isText
                    v-model="row.insCoverage.insuranceName"
                    :config="{
                      type: 'input',
                      width: '100%',
                    }"
                  >
                  </TextInput>
                </template>
              </el-table-column>
              <el-table-column prop="date" label="限额类型">
                <template slot-scope="{ row }">
                  <TextInput
                    isText
                    v-model="row.limitTypeStrings"
                    :config="{
                      type: 'input',
                      width: '100%',
                    }"
                  >
                  </TextInput>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="限额值">
                <template slot-scope="{ row }">
                  <TextInput
                    isText
                    v-model="row.limitValueStrings"
                    :config="{
                      type: 'input',
                      width: '100%',
                    }"
                  >
                  </TextInput>
                </template>
              </el-table-column>
              <el-table-column prop="date" label="操作" width="100">
                <template slot-scope="{ $index, row }">
                  <el-button
                    v-if="!isEdit"
                    @click="editRiskRow(row, $index)"
                    type="text"
                    style="color: #0080ff"
                    >查看详情</el-button
                  >
                  <el-button
                    v-if="isEdit"
                    @click="editRiskRow(row, $index)"
                    type="text"
                    style="color: #0080ff"
                    >编辑</el-button
                  >
                  <el-button
                    v-if="isEdit"
                    @click="deleteRiskRow($index, row, 'coverageIds')"
                    type="text"
                    style="color: #f64a2d"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <div v-if="isEdit" class="table-add-btn" @click="addRisk">
              添加险别
            </div>
          </div>
        </AssociationScrollView>
        <AssociationScrollView title="服务方案" name="2">
          <div style="margin-top: 24px" flex>
            <el-table
              :data="dataForm.servicePlanList"
              style="width: 100%"
              :header-cell-style="{ background: '#F5F7FA', color: '#606266' }"
            >
              <el-table-column type="index" label="ID" width="55">
                <template slot-scope="{ row, $index }">
                  <el-form-item>
                    {{ $index + 1 }}
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="serviceName" label="服务项名称">
                <template slot-scope="{ row, $index }">
                  <el-form-item
                    :prop="'servicePlanList.' + $index + '.serviceName'"
                    :rules="{
                      required: true,
                      message: '请输入服务项名称',
                      trigger: ['change', 'blur'],
                    }"
                  >
                    <TextInput
                      @returnVal="getKeyToValue($event, row)"
                      :isText="!isEdit"
                      v-model="row.serviceDictId"
                      :config="{
                        type: 'select',
                        width: '100%',
                        modelTextKey: 'serviceName',
                        option: dictList.ServiceProjectOptions,
                      }"
                      :optionConfig="{
                        value: 'id',
                        label: 'serviceName',
                      }"
                    >
                    </TextInput>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="date" label="服务项目标准">
                <template slot-scope="{ row }">
                  <el-form-item>
                    <TextInput
                      :isText="!isEdit"
                      v-model="row.serviceStandard"
                      :config="{
                        type: 'input',
                        width: '100%',
                      }"
                    >
                    </TextInput>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="date" label="服务项目金额">
                <template slot-scope="{ row }">
                  <el-form-item>
                    <TextInput
                      :isText="!isEdit"
                      v-model="row.serviceAmount"
                      :config="{
                        type: 'input',
                        width: '100%',
                        isNum: true,
                      }"
                    >
                    </TextInput>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                v-if="isEdit"
                prop="date"
                label="操作"
                width="100"
              >
                <template slot-scope="{ $index, row }">
                  <el-form-item>
                    <el-button
                      @click="deleteSerRow($index, row, 'servicePlanIds')"
                      type="text"
                      style="color: #f64a2d"
                      >删除</el-button
                    >
                  </el-form-item>
                </template>
              </el-table-column>
            </el-table>
            <div v-if="isEdit" @click="addSerRow" class="table-add-btn">
              添加服务
            </div>
          </div>
        </AssociationScrollView>
      </AssociationScroll>
    </el-form>
    <ComSafeguardSchemeAdd
      :show.sync="showAdd"
      :isEdit="isEdit"
      :detailData="currentRisk"
      @submit="submitAdd"
    ></ComSafeguardSchemeAdd>
    <!-- 底部按钮 -->
    <div class="sd-bottom-btn">
      <el-button @click="back">返回</el-button>
      <el-button v-if="isEdit" type="primary" @click="submitForm"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script>
import AssociationScroll from "@/components/AssociationScroll/AssociationScroll.vue";
import AssociationScrollView from "@/components/AssociationScroll/AssociationScrollView.vue";
import TextInput from "../components/TextInput";
import TextInputContainer from "../components/TextInputContainer";
import ComSafeguardSchemeAdd from "../components/ComSafeguardSchemeAdd.vue";
import { dictionaryBatch } from "@/api/policy";
import {
  addOrUpdateInsuranceCompany,
  getServiceProjectOptions,
} from "@/api/safeDuty.js";
import _ from "lodash";
export default {
  components: {
    AssociationScroll,
    AssociationScrollView,
    TextInput,
    TextInputContainer,
    ComSafeguardSchemeAdd,
  },
  props: {
    detailData: {
      type: Object,
      default: () => {
        return null;
      },
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showAdd: false,
      rules: {},
      dictList: { ServiceProjectOptions: [] },
      //当前选择的险别
      currentRisk: null,
      dataForm: {
        branch: "",
        companyKey: "",
        companyName: "",
        companyValue: "",
        coverageIds: [],
        servicePlanIds: [],
        //弹框险种
        coverageTypeIds: [],
        coverageList: [],
        servicePlanList: [],
      },
      baseItemsConfig: [
        {
          label: "保险公司：",
          type: "select",
          required: true,
        },
        {
          label: "分支结构：",
          type: "select",
          required: true,
        },
      ],
      serciceItemsConfig: [
        {
          label: "服务种类：",
          type: "select",
        },
        {
          label: "对应服务项目名称：",
          type: "select",
        },
        {
          label: "服务负责人：",
          type: "select",
        },
        {
          label: "服务类型：",
          type: "select",
        },
        {
          label: "参与人数：",
          type: "inputNumber",
        },
        {
          label: "服务时间：",
          type: "datePicker",
        },
        {
          label: "服务地点：",
          type: "input",
        },
        {
          label: "备注：",
          type: "input",
        },
        {
          label: "服务费用：",
          type: "input",
        },
      ],
    };
  },
  watch: {
    detailData: {
      handler(n) {
        let res = this.handleListDataJoin(n.coverageList);
        n.coverageList = res;
        this.dataForm = { ...this.dataForm, ...n };
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getDictionarySd();
    },
    getKeyToValue(res, obj) {
      if (res.type == "companyName") {
        this.dataForm.companyName = res.data.dictValue;
        this.dataForm.branchKey = "";
        this.dataForm.branchName = "";
        this.dataForm.branchList = [];
        let branchList = res.data.remark.split(",").map((bc) => {
          return {
            dictKey: bc,
            dictValue: bc,
          };
        });
        this.$set(this.dictList, "branchList", branchList);
      } else if (res.type == "serviceName") {
        obj.serviceName = res.data.serviceName;
        obj.serviceAmount = res.data.serviceAmount;
        obj.serviceStandard = res.data.serviceStandard;
      }
    },
    getDictionarySd() {
      let dictKeys = ["insuranceCompany"];
      dictionaryBatch({
        codes: dictKeys.join(","),
      }).then((res) => {
        if (res && res.data) {
          dictKeys.forEach((key) => {
            this.$set(this.dictList, key, res.data[key]);
          });
        }
      });
      // 获取服务项下拉
      getServiceProjectOptions().then((res) => {
        this.$set(this.dictList, "ServiceProjectOptions", res.data);
      });
    },
    deleteSerRow(i, item, type) {
      item.id && this.dataForm[type].push(item.id);
      this.dataForm.servicePlanList.splice(i, 1);
    },
    deleteRiskRow(i, item, type) {
      item.insCoverage.id && this.dataForm[type].push(item.insCoverage.id);
      this.dataForm.coverageList.splice(i, 1);
    },
    addSerRow() {
      this.dataForm.servicePlanList.push({
        serviceName: "",
        serviceStandard: "",
        serviceAmount: "",
      });
    },
    submitAdd(e, delids) {
      delids.length && this.dataForm.coverageTypeIds.push(...delids);
      let res = this.handleListDataJoin([e]);
      if (e.index === "" || e.index === undefined) {
        this.dataForm.coverageList.push(res[0]);
      } else {
        this.dataForm.coverageList.splice(e.index, 1, res[0]);
      }
    },
    //处理保障内容表格数据
    handleListDataJoin(arr = []) {
      let data = _.cloneDeep(arr);
      data.forEach((item) => {
        let limitTypeStrings = item.insCoverageTypeList.map((item) => {
          return item.limitType;
        });
        let limitValueStrings = item.insCoverageTypeList.map((item) => {
          return item.limitValue;
        });
        limitTypeStrings = limitTypeStrings.join(",");
        limitValueStrings = limitValueStrings.join(",");
        item.limitTypeStrings = limitTypeStrings;
        item.limitValueStrings = limitValueStrings;
      });
      return data;
    },
    editRiskRow(item, index) {
      let obj = _.cloneDeep(item);
      obj.index = index;
      this.currentRisk = obj;
      this.showAdd = true;
    },
    addRisk() {
      this.currentRisk = null;
      this.showAdd = true;
    },
    submitForm() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          addOrUpdateInsuranceCompany(this.dataForm).then((res) => {
            this.$message({
              type: "success",
              message: "成功!",
            });
            this.back();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    back() {
      this.$router.go(-1);
      // this.$router.replace({ path: this.$route.path });
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-col-100 {
  height: 100%;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  overflow: auto;
}
.table-add-btn {
  background: #ffffff;
  border: 1px solid #dee3e6;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  color: #00bc0d;
  cursor: pointer;
}
.sd-bottom-btn {
  width: 100%;
  min-height: 80px;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 8px 8px 8px 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 24px;
  z-index: 99;
}
</style>
