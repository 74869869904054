<template>
  <comForm :isEdit="$route.params.isEdit" :detailData="detailData"></comForm>
</template>
<script>
import comForm from "../subPage/comForm";
import { getInsuranceCompanyDetail } from "@/api/safeDuty.js";
export default {
  components: { comForm },
  data() {
    return {
      detailData: null,
    };
  },
  created() {
    if (this.$route.params.id) {
      this.getDetail(this.$route.params.id);
    } else {
      this.detailData = null;
    }
  },
  methods: {
    async getDetail(id = "") {
      let res = await getInsuranceCompanyDetail({
        id: this.$route.params.id,
      });
      this.detailData = res.data;
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped></style>
