<template>
  <el-dialog
    title="添加"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div>
      <el-form ref="dataForm" :model="dataForm" :rules="rules">
        <el-form-item prop="insCoverage.insuranceName">
          <TextInput
            :isText="!isEdit"
            v-model="dataForm.insCoverage.insuranceName"
            :config="{
              type: 'input',
              required: true,
              label: '险别名称：',
            }"
          >
          </TextInput>
        </el-form-item>
        <div style="margin-top: 24px">
          <el-table
            :data="dataForm.insCoverageTypeList"
            style="width: 100%"
            :header-cell-style="{ background: '#F5F7FA', color: '#606266' }"
          >
            <el-table-column prop="name" label="险种">
              <template slot-scope="{ row }">
                <TextInput
                  :isText="!isEdit"
                  @returnVal="getKeyToValue($event, row)"
                  v-model="row.typeKey"
                  :config="{
                    type: 'select',
                    width: '100%',
                    modelTextKey: 'typeName',
                    option: [
                      {
                        value: 1,
                        label: '主险',
                      },
                      {
                        value: 2,
                        label: '附加险',
                      },
                    ],
                  }"
                >
                </TextInput>
              </template>
            </el-table-column>
            <el-table-column prop="date" label="限额类型">
              <template slot-scope="{ row }">
                <TextInput
                  :isText="!isEdit"
                  v-model="row.limitType"
                  :config="{
                    type: 'input',
                    width: '100%',
                  }"
                >
                </TextInput>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="限额值">
              <template slot-scope="{ row }">
                <TextInput
                  :isText="!isEdit"
                  v-model="row.limitValue"
                  :config="{
                    type: 'input',
                    width: '100%',
                    isNum: true,
                  }"
                >
                </TextInput>
              </template>
            </el-table-column>
            <el-table-column prop="date" label="相关说明">
              <template slot-scope="{ row }">
                <TextInput
                  :isText="!isEdit"
                  v-model="row.description"
                  :config="{
                    type: 'input',
                    width: '100%',
                  }"
                >
                </TextInput>
              </template>
            </el-table-column>
            <el-table-column prop="date" label="基础费率">
              <template slot-scope="{ row }">
                <TextInput
                  :isText="!isEdit"
                  v-model="row.baseRate"
                  :config="{
                    type: 'input',
                    width: '100%',
                    isNum: true,
                  }"
                >
                </TextInput>
              </template>
            </el-table-column>
            <el-table-column v-if="isEdit" prop="date" label="操作" width="100">
              <template slot-scope="{ $index, row }">
                <el-button
                  @click="handleDelete($index, row)"
                  type="text"
                  style="color: #f64a2d"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div v-if="isEdit" @click="addItem" class="table-add-btn">添加</div>
          <!-- 用于显示错误信息 -->
          <el-form-item prop="insCoverageTypeList"> </el-form-item>
        </div>
        <div style="display: flex">
          <TextInput
            :isText="!isEdit"
            v-model="dataForm.insCoverage.accidentCompensationLimit"
            :config="{
              type: 'input',
              label: '保单每次事故赔偿限额：',
            }"
          >
          </TextInput
          ><TextInput
            style="margin-left: 160px"
            :isText="!isEdit"
            v-model="dataForm.insCoverage.accumulativeCompensationLimit"
            :config="{
              type: 'input',
              label: '保单累计赔偿限额：',
            }"
          >
          </TextInput>
        </div>
        <TextInput
          :isText="!isEdit"
          v-model="dataForm.insCoverage.deductible"
          :config="{
            type: 'textarea',
            label: '免赔额：',
            width: '100%',
          }"
        >
        </TextInput>
        <TextInput
          :isText="!isEdit"
          v-model="dataForm.insCoverage.specialAgreement"
          :config="{
            type: 'textarea',
            label: '特别约定：',
            width: '100%',
          }"
        >
        </TextInput>
        <TextInput
          :isText="!isEdit"
          v-model="dataForm.insCoverage.limitDescription"
          :config="{
            type: 'textarea',
            label: '限额描述：',
            width: '100%',
          }"
        >
        </TextInput>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button v-if="!isEdit" @click="dialogVisible = false">关闭</el-button>
      <el-button v-if="isEdit" @click="resetForm">重置</el-button>
      <el-button v-if="isEdit" @click="submitForm" type="primary"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import TextInput from "./TextInput.vue";
import _ from "lodash";
import { verifyArryEmpty } from "../js/verify.js";
export default {
  name: "ComSafeguardSchemeAdd",
  components: {
    TextInput,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    detailData: {
      type: Object,
      default: () => {
        return null;
      },
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    show(n) {
      this.dialogVisible = n;
    },
    dialogVisible(n) {
      if (!n) {
        this.$emit("update:show", false);
      }
    },
    detailData: {
      deep: true,
      handler(n) {
        this.coverageTypeIds = [];
        if (n) {
          console.log("复制");
          this.dataForm = _.cloneDeep(n);
          this.oldObject = _.cloneDeep(n);
        } else {
          this.dataForm = {
            insCoverage: {
              insuranceName: "",
              accidentCompensationLimit: "",
              accumulativeCompensationLimit: "",
              deductible: "",
              limitDescription: "",
              specialAgreement: "",
            },
            insCoverageTypeList: [],
          };
        }
      },
    },
  },
  data() {
    return {
      rules: {
        "insCoverage.insuranceName": [
          {
            required: true,
            message: "请输入险别名称",
            trigger: "change",
          },
        ],
        insCoverageTypeList: [
          {
            validator: verifyArryEmpty,
            message: "请添加险种数据",
            trigger: "blur",
          },
        ],
      },
      dialogVisible: false,
      dataForm: {
        insCoverage: {
          insuranceName: "",
          accidentCompensationLimit: "",
          accumulativeCompensationLimit: "",
          deductible: "",
          limitDescription: "",
          specialAgreement: "",
        },
        insCoverageTypeList: [],
      },
      coverageTypeIds: [],
      oldObject: {
        insCoverage: {
          insuranceName: "",
          accidentCompensationLimit: "",
          accumulativeCompensationLimit: "",
          deductible: "",
          limitDescription: "",
          specialAgreement: "",
        },
        insCoverageTypeList: [],
      },
    };
  },
  methods: {
    addItem() {
      this.dataForm.insCoverageTypeList.push({
        baseRate: "",
        description: "",
        limitType: "",
        limitValue: "",
        typeKey: "",
        typeName: "",
        typeValue: "",
      });
    },
    handleDelete(i, item) {
      item.id && this.coverageTypeIds.push(item.id);
      this.dataForm.insCoverageTypeList.splice(i, 1);
    },
    //提交
    submitForm() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          this.$emit(
            "submit",
            _.cloneDeep(this.dataForm),
            this.coverageTypeIds
          );
          this.clearForm();
          this.dialogVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //保存原始值
    keepObj() {
      //this.oldObject = _.cloneDeep();
    },
    //重置表单
    resetForm() {
      this.coverageTypeIds = [];
      this.dataForm = _.cloneDeep(this.oldObject);
    },
    //清空
    clearForm() {
      this.coverageTypeIds = [];
      let keys = Object.keys(this.dataForm);
      keys.forEach((key) => {
        if (this.dataForm[key] instanceof Array) {
          this.dataForm[key] = [];
        } else if (this.dataForm[key] instanceof Object) {
          Object.keys(this.dataForm[key]).forEach((key2) => {
            this.dataForm[key][key2] = "";
          });
        }
      });
    },
    handleClose(done) {
      this.clearForm();
      done();
    },
    getKeyToValue(res, res2) {
      res2[res.type] = res.data.label;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-add-btn {
  background: #ffffff;
  border: 1px solid #dee3e6;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  color: #00bc0d;
  cursor: pointer;
}
</style>
