var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":"添加","visible":_vm.dialogVisible,"before-close":_vm.handleClose,"close-on-click-modal":false,"destroy-on-close":""},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',[_c('el-form',{ref:"dataForm",attrs:{"model":_vm.dataForm,"rules":_vm.rules}},[_c('el-form-item',{attrs:{"prop":"insCoverage.insuranceName"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
            type: 'input',
            required: true,
            label: '险别名称：',
          }},model:{value:(_vm.dataForm.insCoverage.insuranceName),callback:function ($$v) {_vm.$set(_vm.dataForm.insCoverage, "insuranceName", $$v)},expression:"dataForm.insCoverage.insuranceName"}})],1),_c('div',{staticStyle:{"margin-top":"24px"}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.dataForm.insCoverageTypeList,"header-cell-style":{ background: '#F5F7FA', color: '#606266' }}},[_c('el-table-column',{attrs:{"prop":"name","label":"险种"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                  type: 'select',
                  width: '100%',
                  modelTextKey: 'typeName',
                  option: [
                    {
                      value: 1,
                      label: '主险',
                    },
                    {
                      value: 2,
                      label: '附加险',
                    },
                  ],
                }},on:{"returnVal":function($event){return _vm.getKeyToValue($event, row)}},model:{value:(row.typeKey),callback:function ($$v) {_vm.$set(row, "typeKey", $$v)},expression:"row.typeKey"}})]}}])}),_c('el-table-column',{attrs:{"prop":"date","label":"限额类型"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                  type: 'input',
                  width: '100%',
                }},model:{value:(row.limitType),callback:function ($$v) {_vm.$set(row, "limitType", $$v)},expression:"row.limitType"}})]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"限额值"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                  type: 'input',
                  width: '100%',
                  isNum: true,
                }},model:{value:(row.limitValue),callback:function ($$v) {_vm.$set(row, "limitValue", $$v)},expression:"row.limitValue"}})]}}])}),_c('el-table-column',{attrs:{"prop":"date","label":"相关说明"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                  type: 'input',
                  width: '100%',
                }},model:{value:(row.description),callback:function ($$v) {_vm.$set(row, "description", $$v)},expression:"row.description"}})]}}])}),_c('el-table-column',{attrs:{"prop":"date","label":"基础费率"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                  type: 'input',
                  width: '100%',
                  isNum: true,
                }},model:{value:(row.baseRate),callback:function ($$v) {_vm.$set(row, "baseRate", $$v)},expression:"row.baseRate"}})]}}])}),(_vm.isEdit)?_c('el-table-column',{attrs:{"prop":"date","label":"操作","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function({ $index, row }){return [_c('el-button',{staticStyle:{"color":"#f64a2d"},attrs:{"type":"text"},on:{"click":function($event){return _vm.handleDelete($index, row)}}},[_vm._v("删除")])]}}],null,false,459327180)}):_vm._e()],1),(_vm.isEdit)?_c('div',{staticClass:"table-add-btn",on:{"click":_vm.addItem}},[_vm._v("添加")]):_vm._e(),_c('el-form-item',{attrs:{"prop":"insCoverageTypeList"}})],1),_c('div',{staticStyle:{"display":"flex"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
            type: 'input',
            label: '保单每次事故赔偿限额：',
          }},model:{value:(_vm.dataForm.insCoverage.accidentCompensationLimit),callback:function ($$v) {_vm.$set(_vm.dataForm.insCoverage, "accidentCompensationLimit", $$v)},expression:"dataForm.insCoverage.accidentCompensationLimit"}}),_c('TextInput',{staticStyle:{"margin-left":"160px"},attrs:{"isText":!_vm.isEdit,"config":{
            type: 'input',
            label: '保单累计赔偿限额：',
          }},model:{value:(_vm.dataForm.insCoverage.accumulativeCompensationLimit),callback:function ($$v) {_vm.$set(_vm.dataForm.insCoverage, "accumulativeCompensationLimit", $$v)},expression:"dataForm.insCoverage.accumulativeCompensationLimit"}})],1),_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
          type: 'textarea',
          label: '免赔额：',
          width: '100%',
        }},model:{value:(_vm.dataForm.insCoverage.deductible),callback:function ($$v) {_vm.$set(_vm.dataForm.insCoverage, "deductible", $$v)},expression:"dataForm.insCoverage.deductible"}}),_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
          type: 'textarea',
          label: '特别约定：',
          width: '100%',
        }},model:{value:(_vm.dataForm.insCoverage.specialAgreement),callback:function ($$v) {_vm.$set(_vm.dataForm.insCoverage, "specialAgreement", $$v)},expression:"dataForm.insCoverage.specialAgreement"}}),_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
          type: 'textarea',
          label: '限额描述：',
          width: '100%',
        }},model:{value:(_vm.dataForm.insCoverage.limitDescription),callback:function ($$v) {_vm.$set(_vm.dataForm.insCoverage, "limitDescription", $$v)},expression:"dataForm.insCoverage.limitDescription"}})],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[(!_vm.isEdit)?_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("关闭")]):_vm._e(),(_vm.isEdit)?_c('el-button',{on:{"click":_vm.resetForm}},[_vm._v("重置")]):_vm._e(),(_vm.isEdit)?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submitForm}},[_vm._v("确 定")]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }