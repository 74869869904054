<template>
  <div ref="container" :class="flex ? 'container2' : 'container'">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "TextInputContainer",
  props: {
    textShow: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default() {
        return {};
      },
    },
    flex: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    textShow: {
      immediate: true,
      handler(n) {
        this.handlerChildren(n);
      },
    },
  },
  mounted() {
    this.handlerChildren(this.textShow);
  },
  methods: {
    handlerChildren(n) {
      let els = this.$children || [];
      els.forEach((item) => {
        item.textShow = n;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 460px);
}

.container2 {
  display: flex;
}
</style>
